import React from "react"
import { Link } from "gatsby"
import { Section, Container, SectionDescription, SectionTitle, ExploreMore} from "../../components/Section"
import PrimaryButton from "../../components/Button/PrimaryButton"

const WelcomeSection = () => {
	return(
        <Section ept="60px" epb="60px" xpt="40px" xpb="40px" pt="30px" pb="30px" bgColor="#fff" className="section-welcome">
            <Container className="container" maxWidth="1280px">
                <SectionTitle textAlign="center" mb="20px">High-quality Metal Buildings Delivered Directly To You</SectionTitle>
                <SectionDescription mb="30px" textAlign="center">
                    <p>EMB Metal Buildings is your trusted manufacturer for high-quality steel buildings. Our commitment to excellence has earned us a reputation as one of the top metal building manufacturers in the country. We understand the importance of providing exceptional service and fast delivery times. That's why we work with you to design, customize, engineer, finance, and deliver your building promptly and efficiently. You can trust us to provide durable, versatile, and affordable metal buildings that meet your specific needs and exceed your expectations. </p>
                    <p>Ready to get started on your building project? Give us a call. <a href="tel:4792235995" aria-label="phone number">(479) 223-5995</a></p>
                </SectionDescription>
                <ExploreMore mt="30px"><Link to="/about-us/"><PrimaryButton text="Know More" /></Link></ExploreMore>
            </Container>
        </Section>
	)
}
export default WelcomeSection
