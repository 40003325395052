import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HeroSection from "../sections/Home/HeroSection";
import ProductSection from "../sections/Home/ProductSection";
import WelcomeSection from "../sections/Home/WelcomeSection";

const IndexPage = ({ location, data }) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Engineered Metal Building",
    url: "https://www.engineeredmetalbuildings.com/",
    logo: "https://www.engineeredmetalbuildings.com/static/dedb285d8979bb29077ae91ec8181021/5470d/logo.webp",
    sameAs: [
      "https://www.facebook.com/engineeredmetalbuildings",
      "https://www.instagram.com/engineeredmetalbuildings",
      "https://youtube.com/@EngineeredMetalBuildings",
    ],
  };
  return (
    <Layout location={location}>
      <Seo
        title="EMB Metal Buildings | High-Quality Structures for Your Every Need"
        description="At EMB Metal Buildings, we provide a wide range of strong, durable, and affordable structures to meet all your storage needs. Order your building today!"
        schemaMarkup={schema}
      />
      <HeroSection />
      <ProductSection data={data.allContentfulProduct.edges} />
      <WelcomeSection />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    allContentfulProduct(
      sort: { fields: skuNumber, order: ASC }
      limit: 6
    ) {
      edges {
        node {
          id
          url
          rto
          financingAvailable
          skuNumber
          title
          image {
            gatsbyImageData(quality: 50, placeholder: BLURRED)
            fluid {
              srcWebp
            }
          }
          price
          width
          length
          height
          city
          state
          zipcode
          location {
            lat
            lon
          }
          buildingCondition
          description {
            childMarkdownRemark {
              html
            }
          }
          productCategories {
            title
          }
          buildingTags
          roofStyleType
          manufacturer
          gaugeTubing
          gaugePanels
        }
      }
    }
  }
`;
