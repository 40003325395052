import React, { useRef } from 'react'
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import QuotePopUpButton from "../../components/QuotePopUpButton"
import WhiteButton from '../../components/Button/WhiteButton';
import {ArrowLeftIcon} from '../../components/Icons';


const SlickCarousel = styled.div`
  position:relative;
  padding-top:64px;
  @media (min-width: 576px) {
    padding-top:0;
  }
  @media (min-width: 1440px) {
    height:100vh;
  }
`
const SlickSlideCarousel = styled.div`
    .slick-slider{
        .slider-track {
            -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        }
        .slick-slide{
            & img {
                width: 100%;
                -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
                transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
                -webkit-transform: scale(1.3);
                transform: scale(1.3);
            }
            &.slick-active{
                & img {
                    -webkit-transform: scale(1);
                    transform: scale(1);
                    -webkit-animation: cssAnimation 8s 1 ease-in-out forwards;
                    animation: cssAnimation 8s 1 ease-in-out forwards;                
                }
            }
        }
        .slick-dots{
            @media (max-width: 575.98px) {
                display:none !important;
            }
            bottom:30px;
            @media (min-width: 992px) {
                bottom:60px;
            }
            @media (min-width: 1200px) {
                bottom:130px;
            }
            @media (min-width: 1440px) {
                bottom:160px;
            }
            li {
                width:auto;
                height:auto;
                margin: 0 4px;
                button{
                    width: 18px;
                    height: 4px;
                    border-radius:3px;
                    background-color:rgba(255,255,255,0.3);
                    padding:0;
                    &:before{
                        display:none;
                    }
                }
                &.slick-active{
                    button{
                        background-color:rgba(255,255,255,0.9);
                    }
                }
                &:nth-child(2){
                    button{
                        width: 48px;
                    }
                }

            }
        }
    }
`

const SlickItem = styled.div`
    position:relative;
    margin:0;
    @media (min-width: 576px) {
        display: flex !important;
    }
`

const SlickCaption = styled.div`
    text-align:center;
    width:100%;
    margin:0 auto;
    padding:20px;
    @media (min-width: 576px) {
        padding:0 20px;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        z-index: 1;
    }
	@media (min-width: 992px) {
		padding:0 30px;
	}
`

const SlickTitle = styled.div`
    display:block;
    position:relative;
    font-weight:700;
    margin-bottom:5px;
    font-size: 20px;
    line-height: 30px;
    @media (min-width: 576px) {
        text-transform:uppercase;
        margin-bottom:10px;
        font-size: 30px;
        line-height: 40px;
        color:#fff;
    }
    @media (min-width: 768px) {
        font-size: 40px;
        line-height: 50px;
    }
    @media (min-width: 768px) {
        font-size: 50px;
        line-height: 60px;
    }
    @media (min-width: 992px) {
        font-size: 60px;
        line-height: 70px;
    }
    @media (min-width: 1200px) {
        font-size: 70px;
        line-height: 80px;
    }
    @media (min-width: 1600px) {
        font-size: 80px;
        line-height: 90px;
    }
`
const SlickLead = styled.div`
    display:block;
    position:relative;
    margin-bottom:10px;
    font-weight:700;
    @media (min-width: 576px) {
        /* text-transform:uppercase; */
        margin-bottom:20px;
        color:#fff;
    }
    font-size: 16px;
    line-height: 28px;
    @media (min-width: 576px) {
        font-size: 22px;
        line-height: 32px;
    }
    @media (min-width: 768px) {
        font-size: 28px;
        line-height: 38px;
    }
    @media (min-width: 992px) {
        font-size: 34px;
        line-height: 44px;
    }
    @media (min-width: 1200px) {
        font-size: 40px;
        line-height: 50px;
    }
`

const SlickButton = styled.div`
    .btn{
        & .icon{
            > svg{
                width:20px;
                @media (min-width: 576px) {
                 width:28px;
                }
            }
        }
        & .text{            
            @media (max-width: 575.98px) {
                font-size: 14px;
                line-height: 30px;
                padding-right: 10px;
            }
        }

        
        background: #373636;
        color:#fff;	
        & .icon{
            > svg{
                fill:#fff;
            }
        }	
        &:before {
            background:#E31D1D;
        }
        @media (min-width: 576px) {
            background: #373636;
            color:#373636;	
            & .icon{
                > svg{
                    fill:#373636;
                }
            }
            &:before {
                background: #fff;			
            }
            &:hover{
                color:#fff;	
                & .icon{
                    > svg{
                        fill:#fff;
                    }
                }
            }
            
        }
    }
`

const SlickImage = styled.div`
    position: relative;
    width: 100%;
    height:100%;
    @media (min-width: 576px) {
        &:after{
            content:'';
            position:absolute;
            top:0;
            left:0;
            bottom:0;
            right:0;
            background-color:rgba(0,0,0,0.5);
        }
    }

`
const ScrollDowns = styled.div`
    position: absolute;
    bottom: 30px;
    right: 30px;
    @media (max-width: 575.98px) {
        display:none;
    }

    &:before{
        content:'';
        position:absolute;
        bottom:calc(100% + 20px);
        left:50%;
        transform:translateX(-50%);
        width:1px;
        height:96px;
        background-color:rgba(255,255,255,0.5);
    }
`

const Mousey = styled.div`
    width: 22px;
    height: 38px;
    border: 2px solid rgba(255,255,255,0.5);
    border-radius: 15px;
    text-align:center;
`
const Scroller = styled.div`
    margin:0 auto;
    width: 3px;
    height: 8px;
    border-radius: 4px;
    background-color: #fff;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
    animation-iteration-count: infinite;
`


const HeroCarousel = () => {
    const settings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'ease-in-out',
        speed: 500,
        touchThreshold: 100,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        fade: true,
        infinite: true,
        draggable: true,
        autoplay: true,
    };

    const sliderRef = useRef(null);

    return (
        <SlickCarousel>
            <SlickSlideCarousel>
                <Slider {...settings} ref={sliderRef}>
                    <SlickItem>
                        <SlickImage><StaticImage src="../../images/banner01.jpg" alt="banner01" /></SlickImage>
                        <SlickCaption>
                            <SlickTitle>Engineered Metal Buildings</SlickTitle>   
                            <SlickLead>Precision Engineered By Experts</SlickLead>
                            <SlickButton>
                                <Link to="/about-us" aria-hidden="true"><WhiteButton text="About Us" iconAfter={<ArrowLeftIcon />} /></Link>
                            </SlickButton>    
                        </SlickCaption>
                    </SlickItem>
                    <SlickItem>
                        <SlickImage><StaticImage src="../../images/banner02.jpg" alt="banner02" /></SlickImage>
                        <SlickCaption>
                            <SlickTitle>Strength Meets Style</SlickTitle>
                            <SlickLead>Garages, Barns, Carports & More</SlickLead>
                            <SlickButton>
                                <Link to="/shop-metal-buildings" aria-hidden="true"><WhiteButton text="View Our Projects"  iconAfter={<ArrowLeftIcon />}/></Link>
                            </SlickButton>    
                        </SlickCaption>
                    </SlickItem>
                    <SlickItem>
                        <SlickImage><StaticImage src="../../images/banner03.jpg" alt="banner03" /></SlickImage>
                        <SlickCaption>
                            <SlickTitle>Need More Than Just a Building...</SlickTitle>
                            <SlickLead>Choose EMB Metal Buildings </SlickLead>
                            <SlickButton>
                                <QuotePopUpButton thanksURL="/thank-you-get-quote" text="Get a Free Quote" iconAfter={<ArrowLeftIcon />} />
                            </SlickButton>    
                        </SlickCaption>
                    </SlickItem>                   
                </Slider>
            </SlickSlideCarousel>
            <ScrollDowns className="scroll-downs">
                <Mousey className="Mousey">
                    <Scroller className="scroller"></Scroller>
                </Mousey>
            </ScrollDowns>
        </SlickCarousel>
    );
}


export default HeroCarousel;